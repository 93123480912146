<template>
    <div class="wrapper mt-5">
        <v-container
            fluid
            class="white rounded"
        >
            <the-main-filter
                v-if="search_fields"
                :search-fields="search_fields"
                :search-values="searchOptions"
                :active-preset="searchOptions.preset"
                :presets="getterSessionLoginPresets"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                    slot-scope="{ events: { updateShortcats } }"
                >
                    <the-filter-preset
                        :active-preset="searchOptions.preset"
                        :presets="getterSessionLoginPresets"
                        @update:shortcat="updateShortcats"
                    />
                </template>
            </the-main-filter>
            <the-login-session-table
                :loading="loading"
                :search-options="searchOptions"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                class="mt-10"
                @sortTable="sortTable"
            />

            <pagination-with-perpage
                :list-lenght="getterLoginSessionList.length"
                :last-page="getterLastPage"
                :search-options="searchOptions"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onFilterTable"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import TheFilterPreset from '@/components/shared/TableFilters/TheFilterPreset'

import {
    ACTION_GET_LOGIN_SESSION_LIST, ACTION_GET_LOGIN_SESSION_PRESETS, ACTION_CLEAR_STORE
} from '@/store/modules/loginSession/action-types';

import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import { searchFieldsHelper } from '@/services/searchFieldsHelpers';
// import TheBatchTable from '@/components/BatchTable/TheBatchTable.vue';
import TheLoginSessionTable from '@/components/LoginSessionTable/TheLoginSessionTable.vue';

const {
    mapActions: mapLoginSessionAction
} = createNamespacedHelpers('loginSession');

export default {
    name: 'LoginSession',
    components: {
        TheMainFilter,
        PaginationWithPerpage,
        TheFilterPreset,
        // TheBatchTable
        TheLoginSessionTable
    },
    data() {
        return {
            loading: true,
            isFirstLoad: true,
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            search_fields: null,
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: '',
                sort_by: 'created_at',
                sort_order: 'DESC',
                from: '',
                to: ''
            },
            // helpers
            isFirstContentLoaded: false
        }
    },
    metaInfo: {
        title: 'Login session'
    },
    computed: {
        ...mapGetters('loginSession', [
            'getterLoginSessionList',
            'getterLastPage',
            'getterSessionLoginPresets'
        ]),
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        sortBy() {
            return this.$route.query.sort_by || 'created_at'
        },
        sortDesc() {
            return this.$route.query.sort_order === 'DESC'
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm[ACTION_CLEAR_STORE]()
        })
    },
    async created() {
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        await this.get_search_fields()
        await this.getListLoginSession()
    },
    methods: {
        async get_search_fields() {
            this.search_fields = await searchFieldsHelper.getSearchField('login-session')
        },
        ...mapLoginSessionAction({
            ACTION_CLEAR_STORE,
            ACTION_GET_LOGIN_SESSION_LIST,
            ACTION_GET_LOGIN_SESSION_PRESETS
        }),
        async getListLoginSession() {
            try {
                this.loading = true
                if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: { ...this.searchOptions } })
                }
                const promises = [
                    this[ACTION_GET_LOGIN_SESSION_LIST]({ ...this.searchOptions }),
                    this[ACTION_GET_LOGIN_SESSION_PRESETS]()
                ]
                await Promise.all(promises)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        async onFilterTable(payload) {
            if (this.isFirstContentLoaded === false) {
                this.searchOptions = { ...this.searchOptions, ...payload }
            } else {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            }
            await this.getListLoginSession()
            this.isFirstContentLoaded = true
        },
        async paginationPageEvent(e) {
            this.searchOptions.page = e
            await this.getListLoginSession()
        },
        async sortTable(e) {
            // eslint-disable-next-line prefer-destructuring
            this.sort.sort_by = e.sortBy[0] || 'clientid'
            this.sort.sort_order = e.sortDesc[0] ? 'DESC' : 'ASC'
            if (!this.isFirstLoad) {
                this.searchOptions = { ...this.searchOptions, ...this.sort, page: 1 }
                await this.getListLoginSession()
            }
            this.isFirstLoad = false
        }
    }
}
</script>

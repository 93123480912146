export const LOGIN_SESSION_TABLE_HEADER = [
    {
        text: 'In-x',
        sortable: false,
        value: 'index',
        width: '50px'
    },
    {
        text: 'Created at',
        sortable: true,
        value: 'created_at',
        width: '175px'
    },
    {
        text: 'User',
        sortable: false,
        value: '',
        width: '200px'
    },
    {
        text: 'Model',
        sortable: true,
        value: 'owner_model',
        width: '125px'
    },
    {
        text: 'Domain',
        sortable: false,
        value: 'domain',
        align: 'center',
        width: '150px'
    },
    {
        text: 'Duration',
        sortable: true,
        value: 'duration',
        width: ''
    },
    {
        text: 'Is online',
        sortable: true,
        value: 'is_online',
        width: '100px'
    },
    {
        text: 'IP',
        sortable: false,
        value: 'ip',
        width: '120px'
    },
    {
        text: 'IP country',
        sortable: false,
        value: '',
        width: '100px'
    }
]

<template>
    <v-data-table
        :headers="tableHeader"
        item-key="id"
        :items="getterLoginSessionList"
        :server-items-length="getterTotal"
        :single-select="true"
        :loading="loading"
        hide-default-footer
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        must-sort
        @update:options="$emit('sortTable', $event)"
    >
        <template v-slot:body="{ items }">
            <table-skeleton
                v-if="loading && (items && items.length === 0)"
                :per-page="searchOptions.per_page"
                :column-count="tableHeaderLength"
            />
            <table-loader
                v-if="loading"
            />
            <tbody>
                <template>
                    <!-- {{ items }} -->
                    <tr
                        v-for="(item, index) in items"
                        :key="item.id"
                        class="hovered-row"
                    >
                        <td>
                            <div class="">
                                <p>{{ (searchOptions.per_page * (searchOptions.page - 1)) + index + 1 }}.</p>
                            </div>
                        </td>
                        <!-- created -->
                        <td
                            class="td_dates"
                            style="position: sticky; left: 0;z-index: 9; background-color: white;"
                        >
                            <div class="mb-2 d-flex align-center">
                                <div>{{ $date.dateFromNow(item.created_at) }}</div>
                                <information-tooltip>
                                    <span>{{ $date.shortText(item.created_at) }}</span>
                                </information-tooltip>
                            </div>
                        </td>
                        <!-- GENERAL -->
                        <!-- user -->
                        <td>
                            <div
                                class="d-flex align-center"
                            >
                                <div
                                    :class="item.owner_model === 'Client' || item.owner_model === 'Writer' ? 'cursor-pointer' : ''"
                                    @click="goToUser(item.owner_model, item.owner_id)"
                                >
                                    <avatar-rolles
                                        :access-status="item.owner_model"
                                        :firstname="item.owner.firstname"
                                        :lastname="item.owner.lastname"
                                        :avatar-url="item.owner.avatar_url"
                                        :is-accent="true"
                                        avatar-size="45"
                                    />
                                </div>
                                <div class="ml-4">
                                    <p class="mb-0 font-weight-bold">
                                        {{ item.owner.firstname }}
                                        {{ item.owner.lastname }}
                                    </p>
                                    <p class="mb-0">
                                        ID: {{ item.owner_id }}
                                    </p>
                                </div>
                            </div>
                        </td>
                        <!-- duration -->
                        <!-- model -->
                        <td>
                            <div>
                                {{ item.owner_model }}
                            </div>
                        </td>
                        <!-- Domain -->
                        <td class="">
                            <div
                                v-if="item.domain"
                            >
                                <domain-favicon
                                    :domain="item.domain"
                                />
                            </div>
                            <span
                                v-else
                                class="d-flex justify-center"
                            >
                                -
                            </span>
                        </td>
                        <td class="domain-batch">
                            {{ item.duration }} min
                        </td>
                        <!-- is online -->
                        <td class="extras-row_col">
                            <div class="d-flex mt-3">
                                <span
                                    class="online-status"
                                    :class="{'online-status--green': item.is_online}"
                                />
                            </div>
                        </td>
                        <!-- IP -->
                        <td>
                            <div>
                                {{ item.ip }}
                            </div>
                        </td>
                        <!-- IP country -->
                        <td>
                            <div
                                v-if="item.ip_country"
                                class="order_table--max-width text-center"
                            >
                                {{ item.ip_country }}
                            </div>
                            <div
                                v-else
                            >
                                -
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
import filtersMixin from '@/mixins/filtersMixin'

import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import TableLoader from '@/components/shared/Tables/TableLoader.vue';

import {
    LOGIN_SESSION_TABLE_HEADER
} from '@/constants/LoginSession'
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon.vue';
import InformationTooltip from '@/components/shared/InformationTooltip.vue';
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles.vue';

export default {
    name: 'LoginSessionTable',
    components: {
        AvatarRolles,
        InformationTooltip,
        DomainFavicon,
        TableSkeleton,
        TableLoader
    },
    mixins: [
        filtersMixin
    ],
    props: {
        searchOptions: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        sortDesc: {
            type: Boolean,
            required: true
        },
        sortBy: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            open: false
        }
    },
    computed: {
        ...mapGetters('loginSession', [
            'getterLoginSessionList',
            'getterTotal'
        ]),
        tableHeader() {
            return LOGIN_SESSION_TABLE_HEADER
        },
        tableHeaderLength() {
            return LOGIN_SESSION_TABLE_HEADER.length + 1
        }
    },
    methods: {
        goToUser(type, id) {
            if (type === 'Client') {
                this.$router.push({
                    name: 'hr-clients-details',
                    params: { id }
                })
            }
            if (type === 'Writer') {
                this.$router.push({
                    name: 'writer-profile',
                    params: { id }
                })
            }
        }
    }
}
</script>

<style lang="scss">
.payroll-page .v-data-table__wrapper {
    overflow: visible!important;
}

.domain-batch .domain {
  justify-content: normal;
}

.batch-orders{
    background-color: #E3F2FD;
    border-color: #E3F2FD;
}

.online-status{
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red;
    box-shadow: 0 0 25px 2px red;
    &--green {
        background: green;
        box-shadow: 0 0 25px 2px green;
    }
}

.cursor-pointer {
    cursor: pointer;
}

</style>
